export const PROJECTS = [
    { id: '1', requests: 30879, title: 'Typin', date: 'May 2023', github: 'TypingGame', languages: ['React'], logo: 'TypinLogo.png', description: "A typing game app that allows people to test their typing speed and accuracy. This app was built using React, and features time and color customization, as well as a leaderboard.", developers: 'Silas Nevstad', version: '1.0.0', url: 'https://www.typin.me'},
    { id: '2', requests: 58424, title: 'Senttrac', date: 'Apr 2023', github: 'SentimentTracker-Frontend', languages: ['React', 'Python'], logo: 'senttracLogo.png', description: 'Senttrac is an innovative analysis tool created to offer real-time insights into the emotions driving online conversations. By leveraging state-of-the-art sentiment analysis models and a cutting-edge Large Language Model (LLM), Senttrac delves into recent tweets, Reddit posts, and news headlines related to your query. Hosted frontend with React using AWS Amplify and backend on Heroku running a Python flask server for API.', developers: 'Silas Nevstad', version: '1.0.1', url: 'https://senttrac.com' },
    { id: '3', requests: 122925, title: 'HumanGPT', date: 'Apr 2023', github: 'Rewriter', languages: ['React','Python'], logo: 'humangptLogo.png', description: 'HumanGPT employs human recognition analysis models in conjunction with ChatGPT to transform text into a more human-like version. Striving for readability and clear communication, the algorithm focuses on retaining the text\'s original meaning while ensuring it is perceived as human-generated content. The backend is developed using JavaScript, while the frontend is built with React.', developers: 'Silas Nevstad', version: '1.0.0', url: 'https://humangpt.me' },
    { id: '4', requests: 0, title: 'AlgoPicks', date: 'Jul 2022', github: '', languages: ['Python', 'Swift'], logo: 'algoPicksLogo.png', description: 'AlgoPicks is a sports prediction and tracking app that uses machine learning algorithms to predict game outcomes. Utilizing the Firebase real-time database, the app allows users to track their bets in real-time and maintain a historical record of them, providing users with insights into their betting patterns and performance over time. With over 15,000 data points, AlgoPicks is able to predict game outcomes with a 70% accuracy rate in 2021. My goal with AlgoPicks is to create a valuable and reliable tool for both sports enthusiasts and those looking to improve their betting.', developers: 'Silas Nevstad', version: '1.3.0', url: '' },
    { id: '5', requests: 110547, title: 'Scramdleb', date: 'Mar 2023', github: 'Sramdleb', languages: ['React'], logo: 'scramdlebLogo.png', description: 'Scrambled is a word puzzle game where players shift letters to form a secret word within a limited number of moves. The game offers different difficulty levels with varying word lengths and number of moves, making it a fun and challenging game for players of all skill levels.', developers: 'Silas Nevstad', version: '2.0.1', url: 'https://scramdleb.com' },
    { id: '6', requests: 244, title: 'Chess Clock', date: 'Jan 2023', github: 'Chess-Clock', languages: ['Swift'], logo: 'chessClockLogo.png', description: 'ChessClock is an app that I developed as a personal project, fueled by the frustration of not having a proper clock when playing chess with friends. I developed it in Swift in under 12 hours, and it enables chess players to play time-controlled games as well as setting custom time controls for each player and keeping track of the time remaining during the game. Its simple interface makes it easy to use for players in all age ranges.', developers: 'Silas Nevstad', version: '1.1.0', url: 'https://apps.apple.com/gb/app/chess-clock-by-sn/id1666157309' },
    { id: '7', requests: 0, title: 'AlgoTrader', date: 'Dec 2021', github: '', languages: ['Python'], logo: '', description: 'To start, the algorithm uses certain criteria such as recent performance and overall financial health to choose a number of stocks. It then conducts a deeper analysis on a smaller subset of those stocks to identify the best fit for the portfolio. Once the final list of stocks is determined, the algorithm computes the appropriate allocation for each stock in the portfolio. To align the current portfolio with the newly generated one, the algorithm executes trades and sends a text message with the list of stocks that were bought or sold. Implemented on an Alpaca paper trading account, the algorithm has delivered an 18% increase in overall portfolio value. Notably, this performance was achieved during a period when the S&P 500 (SPY) decreased by 6.14%.', developers: 'Silas Nevstad', version: '1.2.0', url: '' },
    { id: '8', requests: 214079, title: 'VSCode GPT', date: 'Feb 2023', github: 'GPT-Extension-VSCode', languages: ['JavaScript', 'Node.js'], logo: 'vscodeLogo.png', description: 'GPT is a Visual Studio Code extension that brings the power of OpenAI\'s language models to your fingertips, helping you analyze and debug your code. With just a few clicks, you can get explanations for code snippets or debug outputs to help you better understand what\'s going on in your code.', developers: 'Silas Nevstad', version: '2.0.1', url: 'https://marketplace.visualstudio.com/items?itemName=SilasNevstad.gpthelper' },
    { id: '9', requests: 0, title: 'MiniGPT', date: 'Mar 2023', github: '', languages: ['React Native'], logo: '', description: 'A mobile interface I created for ChatGPT when OpenAI first released it. Key features include saving chat history, model selection and customizable API keys.', developers: 'Silas Nevstad', version: '1.0.0', url: '' },
    { id: '10', requests: 1500, title: 'Categoridle', date: 'Feb 2023', github: 'Categoridle', languages: ['HTML', 'JavaScript', 'CSS'], logo: '', description: 'Categoridle is a website that allows users to play a game similar to the popular game Wordle. However, Categoridle includes an additional feature where users can select a category for the word they are trying to guess, making the game more challenging and fun. Categories so far include: Athletes, Car Brands, Countries, Companies, Captital Cities, Music, Movies, Normal 5 letter words, and a couple others with more to come.', developers: 'Silas Nevstad', version: '1.2.1', url: 'http://categoridle.com' },
    { id: '11',  requests: 0, title: 'SportSpots', date: 'Feb 2023', github: 'SportSpots', languages: ['HTML', 'JavaScript', 'CSS'], logo: '', description: 'I built this website to assist sports enthusiasts in finding the nearest sports field locations, such as a basketball courts, ice rinks, or soccer fields, based on their current location. By utilizing the Google Maps API, users can easily search for sports fields in their area and get directions to the desired location. In addition to providing location-based information, the website also features a chat function that enables users to connect with other sports enthusiasts in the area. Each sports field has its chat room, where users can share information, organize games, or provide updates about the venues.', developers: 'Silas Nevstad', version: '1.0.0', url: '' },
    { id: '12',  requests: 26780, title: 'BuddyAI', date: 'May 2023', github: 'ComposeAI', languages: ['React', 'Python'], logo: 'buddyLogo.png', description: 'Your personal AI co-writer. Have buddy write along with you, suggesting continutations, formalizing your text, improving it or just simply ask it questions. This app is hosted using React and uses a python API hosted on Heroku.', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://buddyai.me' },
    { id: '13', requests: 51297, title: 'Pulse', date: 'Jun 2023', github: 'PulseFrontend', languages: ['React', 'Python'], logo: 'PulseLogo.jpg', description: 'This news outlet offers a diverse array of news categories to browse through. The platform presents concise news items for quick updates, with options to delve deeper into any story that catches your interest. An added feature is an AI-powered chatbot, consistently updated with the latest news, offering interactive discussions for better understanding.\nThe app harnesses ReactJS for front-end, hosted on AWS. Python powers the back-end, providing efficient data handling, hosted on Heroku for easy deployment and scalability.', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://gptnews.me' },
    { id: '14', requests: 27230, title: 'Opexis', date: 'Jun 2023', github:'Opexis', languages: ['React'], logo: 'OpexisLogo.png', description: 'A GPT-powered pocket programmer. An experimental platform that I\'ve built, using GPT as a base, with the aim to facilitate code creation through interactive AI assistance. React app hosted on AWS using Firebase.', developers: 'Silas Nevstad', version: '1.0.0', url:'https://opexis.me' },
    { id: '15', requests: 103413, title: 'Pathr', date: 'Sep 2023', github: 'PLPFrontend', languages: ['React'], logo: 'PathrLogo.png', description: 'A web application where users can input a topic they would like to learn, their experience level, along with optional information, to generate learning paths. Each path comprises steps filled with relevant resources. After generation, users can further modify these paths to fit their preferences. Developed using React and AWS on the frontend and uses Firebase for backend tasks, authentication, and storage of paths.', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://p4thr.com' },
    { id: '16', requests: 0, title: 'Verifi', date: 'Oct 2023', github: 'verifi', languages: ['Python'], logo: 'verifiLogo.png', description: 'This GitHub repository holds a python fact-checking tool that cross-references information with credible sources across the internet. Designed to enhance accuracy and reliability in information dissemination, serving as a resource for verifying facts in real-time.', developers: 'Silas Nevstad', version: '1.0.0', url: 'https://github.com/silasnevstad/verifi' },
    { id: '17', requests: 831903, title: 'OwnAI', date: 'Nov 2023', github: '', languages: ['React', 'Go'], logo: 'theraiLogo.png', description: 'A supercharged AI that evolves with you, OwnAI is a dynamic AI chatbot, similar to ChatGPT, that learns about and adapts to you over time. It\'s designed to offer a conversational experience that feels contextually rich and engaging. Built on GPT-4, it offers tailored conversations and advanced tools like web browsing, humanization, and image generation. Experience AI that\'s truly adaptive and personal.', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://ownai.me' },
    { id: '18', requests: 81956, title: "silas.fun", date: 'Apr 2024', github: 'silas.fun-frontend', languages: ['Go', 'Python', 'React'], logo: 'silasFunLogo.png', description: 'A tiny website to house some fun projects and experiments I\'ve worked on, with more coming soon. The website is built using React and relies on some Go and Python backends.', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://siii.fun' },
    { id: '19', requests: 0, title: 'Sports AI', date: 'Aug 2024', github: 'SportsAI¬', languages: ['Python'], logo: '', description: 'A convolutional neural network (CNN) model that predicts the outcomes of sports games (EPL, NBA, etc). The model has an accompanying data scraper to scrape player, team, schedule and odds data for several sports. The model uses a convolutional layer (typically used for image processing) to process the players from each game as a 3D matrix, flattening the result and appending team and schedule features.', developers: 'Silas Nevstad', version: '1.0.0', url: 'https://github.com/silasnevstad/SportsCNN' },
    // { id: '16', requests: 0, title: 'GPT-Programmer', date: '15 Jun 2023', github: 'GPT-Programmer', languages: ['Python'], logo: '', description: 'A python project that leverages OpenAI\'s GPT to act as a programmer/developer helping people with less experience build things', developers: 'Silas Nevstad', version: '1.0.0', url: 'http://github.com/silasnevstad/GPT-Programmer' },
    // { id: '17', title: 'Casino', date: 'Feb 2023', languages: 'React', logo: '', description: 'I built this simple React web application to allow users to practice their casino skills. The website features a simple game that requires users to guess the appropriate moves to increase their score. The game was designed to be interactive and fun, providing users with an engaging way to improve their knowledge and skillset of various casino games.', developers: 'Silas Nevstad', version: '1.0.0', url: '' },
];

export const PERSONAL_SITE_REQUESTS = "130752";

export const TITLES = [
    "a Programmer",
    "a Full Stack Developer",
    "a Software Developer",
    "a Web Designer",
    "a Data Scientist",
    "an App Developer",
    "a Data Analyst",
    "a Game Developer",
    // "Code Whisperer",
    "an Algorithm Alchemist",
    // "Zenith Zealot",
    "an AI Architect",
    // "Optimization Expert",
    // "Tech Visionary",
];

export const RESUME_DATA = {
    header: {
        name: "Silas Nevstad",
        location: "Boston, MA",
        email: "nevstads@gmail.com",
        linkedIn: "https://www.linkedin.com/in/silas-nevstad-3091a420b/",
        github: "https://github.com/silasnevstad/",
    },
    education: [
        {
            schoolName: "Northeastern University",
            college: "Khoury College of Computer Sciences",
            year: "2021-Present",
            location: "Boston, MA",
            degree: "Candidate for Bachelors in Computer Science",
            coursework: [
                "Algorithms and Data",
                "Computer Systems",
                "Object-Orientated Design",
                "Electrical Engineering and Design",
                "Fundamentals of Computer Science 1 & 2",
                "Fundamentals of Cybersecurity",
                "Programming in C++",
                "Mathematics of Data Models",
                "Mobile Development",
            ],
        },
        {
            schoolName: "Staples High School",
            year: "2017-2021",
            location: "Westport, CT",
            degree: "High School Diploma",
            gpa: "3.8",
            coursework: [
                "AP Computer Science (5/5)",
                "Web Applications",
                "Applied Algorithmic Design",
                "Mobile App Development",
                "AP German (5/5)",
            ],
            activities: [
                "Varsity Ice Hockey",
                "Varsity Track",
            ],
        },
        {
            schoolName: "Bedford Middle School",
            year: "2017-2018",
            location: "Westport, CT",
            gpa: "3.9",
            activities: [
                "Club Soccer",
            ],
        },
        {
            schoolName: "Kingston Grammar School",
            year: "2015-2017",
            location: "Kingston, London",
            coursework: [
                "A* German GCSE (2015)",
                "LAMBDA Certifications (2012-2016)",
            ],
            activities: [
                "Field Hockey",
            ],
        },
    ],
    skills: [
        { name: 'Go', value: 85 },
        { name: 'Python', value: 85 },
        { name: 'Javascript', value: 75 },
        { name: 'C++', value: 66 },
        { name: 'React', value: 80 },
        { name: 'Swift', value: 80 },
        { name: 'Java', value: 68 },
        { name: 'HTML', value: 75 },
        { name: 'CSS', value: 78 },
        { name: 'Dart', value: 45 },
        { name: 'Git', value: 75 },
        { name: 'SQL', value: 70 },
        { name: 'MacOS', value: 90 },
        { name: 'Windows', value: 80 },
        { name: 'Linux', value: 60 },
        { name: 'Assembly', value: 45 },
        { name: 'German', value: 94 },
        { name: 'Norwegian', value: 94 },
        { name: 'English', value: 100 },
    ],
    workExperience: [
        {
            position: "Intern Backend Developer",
            company: "Firi",
            location: "Remote (Oslo, Norway)",
            bulletPoints: [
                "Developed and maintained backend systems in Go, integrating third-party REST APIs and managing data with SQL.",
                "Implemented structured logging and Prometheus business metrics, enhancing application observability and performance monitoring.",
                "Engineered integration tests for databases and APIs, translating core SQL schemas into Go and automating database creation with testcontainers-go."
            ],
        },
        {
            position: "Tech Intern",
            company: "The Bites Company",
            location: "Bridgeport, CT",
            bulletPoints: [
                "Managed social media accounts, resulting in a 25% increase in followers and a 15% increase in engagement",
                "Created and maintained a comprehensive customer information database, resulting in a 15% increase in marketing and sales efficiency"
            ],
        },
    ],
    projects: [
        {
            title: "OwnAI",
            status: "Personal",
            languages: ["React", "Go"],
            bulletPoints: [
                "Conceived OwnAI, an AI chat experience that evolves and tailors its intelligence to your unique needs.",
                "Developed a user-friendly web application hosted on AWS coupled with a Firebase and Google Cloud backend, serving 1000+ users.",
            ],
        },
        {
            title: "VS Code GPT",
            status: "Personal",
            languages: ["Javascript"],
            bulletPoints: [
                "Developed a VS Code extension leveraging OpenAI's ChatGPT to enhance code generation, comprehension and debugging",
                "Successfully integrated the extension into the VS Code ecosystem, enhancing the coding experience for 125,000+ users."
            ],
        },
        {
            title: "AlgoTrader",
            status: "Personal",
            languages: ["Python"],
            bulletPoints: [
                "Devised and deployed a machine learning-powered algorithm for automated stock trading, generating an 18% profit against a 6% decrease in SPY, showcasing resilience and profitability",
                "Incorporated data from NYSE indices for comprehensive trade screening and predictive buying/selling, optimizing investment decisions"
            ],
        },
        {
            title: 'Pathr',
            status: 'Personal',
            languages: ['React', 'Firebase', 'AWS'],
            bulletPoints: [
                "Crafted a web app that generates personalized learning paths based on user-defined criteria, benefiting countless learners seeking structured guidance.",
                "Implemented a responsive frontend using React and AWS, coupled with Firebase to manage backend tasks, authentication, and data storage.",
                "Empowered users with post-generation customization features, streamlining educational journeys for a diverse audience."
            ],
        },
    ],
};